:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #111111; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #e03a3c; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #212529; /* The default color of the main navbar links */
  --nav-hover-color: #e03a3c; /* Applied to main navbar links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #e03a3c; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

ul li {
  list-style: none;
}

.carousel-image {
  /* Default height for larger screens */
  height: 100vh; /* Adjust as needed */

  /* Media query for smaller screens (e.g., mobile devices) */
  @media (max-width: 768px) {
    height: 50vh; /* Adjust as needed */
  }
}

a {
  text-decoration: none;
}

#header {
  background-color: rgba(0, 0, 0, 0) !important    ;
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  max-height: 90px;
  position: absolute;
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}

#header .logo a {
  color: #0f394c;
}

#header .logo img {
  max-height: 70px;
}
.logo img {
  max-height: 70px;
}

.logo-img1 {
  padding-right: 20px;
}

#header .btn-get-started {
  font-family: "Dosis", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding-right: 30px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 100px;
  transition: 0.5s;
  color: #fff;
  background: #95d2ed;
  text-transform: uppercase;
  text-decoration: none;
}
.text-justify {
  text-align: justify;
}
.navbar {
  padding: 0;
}

.navbar .btn-light:hover {
  background-color: transparent;
  color: #fff;
}
/* .navbar .nav-btn{
        margin: 10px;
      }
      .navbar .nav-btn-left {
        margin-left: 10px;
      } */
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0 10px 30px; */
  padding: 0 10px;
  font-family: "Dosis", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  transition: 0.3s;
  text-transform: uppercase;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
}

.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: contents;
  }
  .logo a {
    margin-top: 10px;
  }
  .logo-img2 {
    width: 70px;
    height: 70px;
  }
  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  display: none;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #0d6efd;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .nav-btn-mobile {
  background-color: #0d6efd;
  color: white;
}

.navbar-mobile .nav-btn-mobile:hover {
  background-color: #0d6efd;
  color: white;
}

.hero {
  --default-color: #ffffff;
  --background-color: #000000;
  --heading-color: #ffffff;
  width: 100%;
  min-height: calc(100vh - 300px);
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-color);
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 80%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin: 10px 0 0 0;
  font-size: 24px;
}

.hero .btn-get-started {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  transition: 0.3s;
  background: var(--accent-color);
  color: var(--contrast-color);
  cursor: pointer;
}

.hero .btn-get-started:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-weight: 600;
  color: var(--default-color);
}

.hero .btn-watch-video i {
  color: var(--accent-color);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: var(--accent-color);
}

.hero .btn-watch-video:hover i {
  color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 18px;
  }
  .title-marque {
    width: 35% !important;
  }
  .news {
    width: 65% !important;
  }

  .title-marque h3 {
    font-size: 15px !important;
    text-align: center !important;
    align-items: center !important;
  }
}

.contact-bg {
  background-color: #061429;
}

.founder-note {
  background: #fff;
  overflow: hidden;
  padding: 40px 0;
}
.founder-note.sws-wrap {
  background: #fff;
  padding: 0;
  overflow: hidden;
}
.founder-note .images {
  overflow: hidden;
  width: 400px;
  height: 400px;
  border: 4px solid #ddd;
  border-radius: 200px;
  margin: 0px auto;
}
.founder-note .images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.founder-note .images1 {
  overflow: hidden;
  width: 400px;
  height: 400px;
  margin: 0px auto;
}
.founder-note .images1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.founder-note .founder-note-in {
  padding: 0px 0px;
  position: relative;
}
.founder-note .founder-note-in img {
  position: absolute;
  top: 150px;
  left: 10px;
}
.founder-note .founder-note-in small {
  font-size: 14px;
  line-height: 26px;
  color: #2fa600;
  display: block;
  margin: 0 0 20px 0;
}

.founder-note .founder-note-in h4 {
  font-size: 34px;
  display: block;
  font-weight: normal;
  margin: 0 0 40px 0;
  color: #002270;
  font-family: "Roboto Slab", serif;
  position: relative;
}
.contentpart h4 {
  font-size: 28px !important;
  display: block;
  font-weight: normal;
  margin: 0 0 40px 0;
  color: #002270;
  font-family: "Roboto Slab", serif;
  position: relative;
}
.contentpart-one {
  box-shadow: 1px 2px 5px 0px #2fa600;
  padding: 20px 20px;
  margin: 15px 0px;
}
.contentpart-one h4 {
  font-size: 25px !important;
  display: block;
  font-weight: normal;
  margin: 0 0 40px 0;
  color: #002270;
  font-family: "Roboto Slab", serif;
  position: relative;
}
.founder-note .founder-note-in h4:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 2px;
  width: 120px;
  display: block;
  background: #002270;
}
.founder-note .founder-note-in h5 {
  font-size: 32px;
  display: block;
  font-weight: normal;
  margin: 0 0 20px 0;
  color: #002270;
  font-family: "Roboto Slab", serif;
}

.contentpart-one p {
  font-size: 13px !important;
  line-height: 20px !important;
  color: #444;
  display: block;
  margin: 0 0 20px 0;
  text-align: justify;
}
.founder-note .founder-note-in p {
  font-size: 16px;
  line-height: 26px;
  color: #444;
  display: block;
  margin: 0 0 20px 0;
}
.founder-note .founder-note-in strong {
  font-size: 16px;
  display: block;
  margin: 0px 0 0 0;
}
.founder-note .founder-note-in .strtext {
  font-size: 18px;
  display: block;
  margin: 20px 0 0 0;
}
.founder-note-in {
  width: 56%;
  padding: 60px 120px 60px 90px;
}
.whychooseall .founder-note-in {
  width: 62%;
  float: right;
}
.whychooseall:nth-child(even) .founder-note-in {
  float: left;
}
.founder-note-in .about-lis li {
  width: 100%;
  margin-bottom: 7px;
}
.founder-note {
  background: #fff;
  overflow: hidden;
}
.comment {
  /* color: #fff; */
  line-height: 22px;
  font-size: 15px;
  padding: 0;
  margin: 0;
}
.founder-heading h4 {
  font-size: 26px !important;
  display: block;
  font-weight: normal;
  margin: 23px 0 30px 0;
  color: #002270;
  font-family: "Roboto Slab", serif;
  position: relative;
}
.founder-heading h4:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 2px;
  width: 120px;
  display: block;
  background: #002270;
}

.innerslider {
  position: relative;
  padding: 0px 0;
  overflow: hidden;
  height: 100%;
}
.innerslider:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.7;
  display: block;
  top: 0;
}
.innerslider img {
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  height: 100%;
}
.innerslider .contants {
  position: relative;
  width: 100%;
  z-index: 1;
  text-align: left;
  padding: 70px 0;
}
.innerslider .contants h1 {
  font-size: 42px;
  display: block;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 0 30px 0;
}
.innerslider .contants h1 {
  font-size: 25px;
}
.innerslider .contants {
  padding: 40px 0;
}

.his-contant {
  padding: 60px 0 0 0;
}
.his-contant h4 {
  font-size: 24px;
}
.his-contant strong {
  font-size: 16px;
  padding: 0 0 10px 0;
  margin: 0;
  display: block;
}
.his-contant p {
  font-size: 14px;
  padding: 0 0 30px 0;
}

.his-contant .image-aiu {
  width: 100%;
}
.his-contant .image-aiu.ab img {
  width: 100%;
}
.his-contant .detail_guid {
  padding: 0 0 0 10px;
}
.table-12 th {
  padding: 10px;
  color: #000;
  border: 1px solid #dadada;
  text-align: center;
  font-size: 14px;
  background: #f9f6f5;
}
.table-12 {
  width: 100%;
}
.table-12 tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.table-12 td {
  padding: 10px;
  color: #444;
  border: 1px solid #dadada;
  text-align: center;
  font-size: 14px;
}
.table-12 th {
  padding: 10px;
  color: #000;
  border: 1px solid #dadada;
  text-align: center;
  font-size: 14px;
  background: #f9f6f5;
}

.button1 {
  background-image: radial-gradient(
    circle farthest-corner at 14.3% 25.1%,
    rgba(0, 0, 128, 1) 0%,
    rgba(9, 9, 54, 1) 90%
  );
  border-radius: 5px;
  border: 0;
  line-height: 50px;
  height: 50px;
  padding: 0px 40px;
  color: #fff !important;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}
.button1:hover {
  background: #7a2a2a;
  color: #fff !important;
}

.request-call {
  padding: 70px 0 50px 0;
  background-color: #f9f4f4;
}
.request-call-in {
  text-align: center;
  border-radius: 10px;
}
.request-call h4 {
  font-size: 24px;
  color: #222;
  padding: 0px 0 40px 0;
  margin: 0;
}
.request-call-in small {
  font-size: 12px;
}

.request-call-in {
  padding: 52px 0px 24px;
}
.request-call h4 {
  padding: 0 0 10px 0;
}
.contact_info_list {
  display: inline-block;
  list-style: none;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
}
.contact_info_list li {
  display: inline-grid;
  width: 24%;
  margin-top: 13px;
}
.contact_info_list li .img {
  width: 65px;
  height: 65px;
  display: block;
  margin: 0px auto 10px;
}
.contact_info_list li .img img {
  width: 100%;
}
.contact_info_list li span {
  font-size: 18px;
  font-weight: bold;
  color: #222;
  display: block;
}
.contact_info_list li p {
  font-size: 16px;
  font-weight: normal;
  color: #000;
}
.contact_info_list li a {
  font-size: 16px;
  font-weight: normal;
  color: #555;
}
.contact_info_list {
  padding: 0;
  margin: 0;
}
.contact_info_list li {
  width: 100%;
  padding: 20px 0;
}
#welcome {
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: #fff;
  padding: 0px 0 40px 0;
  height: 100%;
}

@media (min-width: 1024px) {
  #welcome {
    background-attachment: fixed;
  }
}

#welcome:before {
  content: "";
  background: rgba(13, 20, 41, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#welcome h1 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

#welcome h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

#welcome h3 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
}

#welcome p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
}

.card-img-top {
  height: 250px;
  /* width: 310px; */
}

.marque {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.ticker {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 30px;
  margin: 0 auto;
}
.news {
  width: 80%;
  background: #0d6efd;
  padding: 0 3%;
}
.title-marque {
  width: 20%;
  text-align: center;
  background: #0e1b4d;
  position: relative;
}
.title-marque:after {
  position: absolute;
  content: "";
  right: -20%;
  border-left: 17px solid #0e1b4d;
  border-top: 15px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 16px solid transparent;
  top: 0px;
}
.title-marque h3 {
  font-size: 24px;
  font-weight: 900;
  color: #fff;
}

.news marquee {
  font-size: 18px;
}
.news-content p {
  font-weight: 900;
  margin-right: 41px;
  display: inline;
  color: #fff;
  padding-bottom: 150px;
}
